export const environment = {
    production: false,
    apiUrl: "https://api-test.eastexcrude.com",
    clientId: "1",
    clientSecret: "BvGz5qJ6Hbt8LLM9R42HIQnr1q8GNL7lYDo1XPom",
    mapbox: {
        accessToken:
            "pk.eyJ1IjoiZWFzdGV4IiwiYSI6ImNqdTQybDNzdzBkY3o0M3M3Z2FpeWU1YTIifQ.Xou9hswu5g_ElwwW9mderw",
    },
    gridLicense:
        "CompanyName=Eastex Crude Company,LicensedApplication=Eastex Crude Application,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-031041,SupportServicesEnd=28_July_2023_[v2]_MTY5MDQ5ODgwMDAwMA==057e39d207d51ab9ce020bf23216e36e",
};
